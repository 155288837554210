export const DIFF_COLUMN_TYPE = [
  {
    techName: 'name',
    name: 'Название шаблона',
  },
  {
    techName: 'techName',
    name: 'Техническое имя формы',
  },
  {
    techName: 'comment',
    name: 'Комментарий',
  },
  {
    techName: 'deletedFormItems',
    name: 'Удаленные контролы',
  },
  {
    techName: 'addedFormItems',
    name: 'Добавленные контролы',
  },
];

export const VERSIONS_TYPE = {
  original: 'Оригинальное значение',
  changed: 'Измененное значение',
};

export const DIFF_VERSIONS_COUNT = 2;
