<template>
  <div class="form-versions-modal">
    <esmp-modal
      v-model="isShowed"
      :closable="false"
      :mask-closable="false"
      :width="800"
    >
      <template #header>
        {{ header }}
      </template>
      <div v-if="versions" class="form-versions-modal__diff">
        <h4 v-if="diffVersionIds.length !== DIFF_VERSIONS_COUNT && isCompared">
          Для сравнения выберите две версии
        </h4>
        <esmp-button
          v-if="isCompared"
          size="small"
          view="outline"
          :disabled="diffVersionIds.length !== DIFF_VERSIONS_COUNT"
          @click="showDiffFormVersionsModal"
        >
          Сравнить
        </esmp-button>
      </div>
      <esmp-table
        class="form-versions-modal__table"
        :columns="filteredColumns"
        :rows="versions"
        :loading="isLoading"
        stripe
      >
        <template #cell-formVersionId="{ tr }">
          {{ tr.formVersionId }}
        </template>
        <template #cell-name="{ tr }">
          {{ tr.name }}
        </template>
        <template #cell-editDatetime="{ tr }">
          {{ getEditDatetime(tr.editDatetime) }}
        </template>
        <template #cell-editorLogin="{ tr }">
          {{ tr.editorLogin }}
        </template>
        <template #cell-isActive="{ tr }">
          <esmp-switch
            v-model="tr.isActive"
            :disabled="tr.isActive"
            @input="setActiveVersion(tr.formVersionId)"
          />
        </template>
        <template #cell-changes="{ tr }">
          <esmp-checkbox
            v-model="tr.isChecked"
            @input="setDiffVersionId(tr.isChecked, tr.formVersionId)"
          />
        </template>
        <template #cell-remove="{ tr }">
          <esmp-button
            size="small"
            view="function"
            icon="trash"
            :disabled="versions && versions.length === 1 || tr.isActive"
            @click="deleteVersions(tr.formVersionId)"
          />
        </template>
      </esmp-table>
      <esmp-pagination-adaptive
        class="form-versions-modal__pagination"
        v-if="pageCount > 1"
        :page-count="pageCount"
        :current-page.sync="currentPage"
      />
      <template #footer>
        <esmp-button view="outline" @click="$emit('hide-form-versions-modal')">
          Закрыть
        </esmp-button>
      </template>
    </esmp-modal>
    <diff-form-versions
      :is-showed="diffFormVersionsShowed"
      :form-id="formVersionsId"
      :diff-version-ids="diffVersionIds"
      :type="type"
      @hide-diff-form-versions-modal="hideDiffFormVersionsModal"
    />
  </div>
</template>

<script>
import isNumber from 'lodash/isNumber';
import { getUTCToLocalDate } from '@/filters/dateFormat';
import { DIFF_VERSIONS_COUNT } from '@/constants/formVersions';
import DiffFormVersions from '@/pages/admin/forms/components/DiffFormVersions.vue';

export default {
  name: 'FormVersions',
  components: { DiffFormVersions },
  props: {
    isShowed: {
      type: Boolean,
      default: false,
    },
    formVersionsId: {
      type: Number,
      default: null,
    },
    header: {
      type: String,
      default: 'Версии',
    },
    type: {
      type: String,
      default: 'forms',
    },
    isCompared: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    formVersionsId(val) {
      if (isNumber(val)) {
        this.getVersions();
      } else {
        this.versions = null;
      }
    },
    currentPage() {
      this.getVersions();
    },
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          title: 'Версия',
          key: 'formVersionId',
        },
        {
          title: 'Название',
          key: 'name',
        },
        {
          title: 'Дата',
          key: 'editDatetime',
        },
        {
          title: 'Кто изменил',
          key: 'editorLogin',
        },
        {
          title: 'Активная',
          key: 'isActive',
        },
        {
          title: 'Сравнить',
          key: 'changes',
        },
        {
          title: '',
          key: 'remove',
        },
      ],
      versions: null,
      diffFormVersionsShowed: false,
      diffVersionIds: [],
      pageCount: 1,
      currentPage: 1,
    };
  },
  computed: {
    filteredColumns() {
      if (!this.isCompared) {
        return this.columns.filter((col) => col.key !== 'changes');
      }
      return this.columns;
    },
  },
  methods: {
    getVersions() {
      this.isLoading = true;
      this.$API[this.type].getFormVersions(this.formVersionsId, this.currentPage - 1).then(({ data }) => {
        this.versions = data.content
          .map((version) => ({
            ...version,
            isChecked: false,
          }));
        this.pageCount = data.totalPages;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    deleteVersions(versionId) {
      this.isLoading = true;
      this.$API[this.type].deleteFormVersion(this.formVersionsId, versionId)
        .then(() => {
          this.versions = this.versions.filter((version) => version.formVersionId !== versionId);
          this.$EsmpNotify.$show('Версия успешно удалена', 'success');
        }).finally(() => {
          this.isLoading = false;
        });
    },
    setActiveVersion(versionId) {
      this.isLoading = true;
      this.$API[this.type].updateFormActiveVersion(this.formVersionsId, versionId).then(() => {
        this.getVersions();
        this.$EsmpNotify.$show('Активная версия успешно изменена', 'success');
      }).finally(() => {
        this.isLoading = false;
      });
    },
    showDiffFormVersionsModal() {
      this.diffFormVersionsShowed = true;
    },
    hideDiffFormVersionsModal() {
      this.diffFormVersionsShowed = false;
      this.clearSelectedDiffVersionsId();
    },
    setDiffVersionId(isChecked, id) {
      const isExist = this.diffVersionIds.find((i) => i === id);
      if (!isExist && isChecked) {
        this.diffVersionIds.push(id);
      } else if (isExist && !isChecked) {
        const index = this.diffVersionIds.findIndex((i) => i === id);
        this.diffVersionIds.splice(index, 1);
      }
    },
    getEditDatetime(date) {
      return getUTCToLocalDate(date, 'DD-MM-YYYY HH:mm:ss');
    },
    clearSelectedDiffVersionsId() {
      this.diffVersionIds = [];
      this.versions = this.versions.map((version) => ({
        ...version,
        isChecked: false,
      }));
    },
  },
  created() {
    this.DIFF_VERSIONS_COUNT = DIFF_VERSIONS_COUNT;
  },
};
</script>

<style lang="scss">
.form-versions-modal {
  &__diff {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: $base-gutter;

    h4 {
      margin-right: $base-gutter;
    }
  }
  &__table {
    margin-bottom: $base-gutter;
  }
  &__pagination {
    justify-content: center;
  }
}
</style>
