<template>
  <div class="documents">
    <esmp-table-wrapper
      title="Шаблоны документов"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <template #header-actions>
        <esmp-button
          view="outline"
          size="small"
          @click="$router.push({ name: 'CreateDocument' })"
        >
          Добавить
        </esmp-button>
      </template>
      <esmp-table
        :columns="columns"
        :rows="list"
        :loading="isLoading"
        stripe
      >
        <template #cell-name="{ tr }">
          {{ tr.name }}
        </template>

        <template #cell-actions="{ tr }">
          <esmp-button-group class="documents__action-buttons">
            <esmp-button
              size="small"
              view="outline"
              icon="doc-edit"
              icon-position="left"
              title="Изменить"
              @click="editDocument(tr.id)"
            />
            <esmp-button
              size="small"
              view="outline"
              icon="workflow"
              icon-position="left"
              title="Версии"
              @click="showDocumentVersions(tr.id)"
            />
            <esmp-button
              size="small"
              view="outline"
              icon="trash"
              icon-position="left"
              title="Удалить"
              @click="showConfirmModal(tr.id)"
            />
          </esmp-button-group>
        </template>
      </esmp-table>
      <esmp-pagination-adaptive
        class="documents__pagination"
        :page-count="pageCount"
        :current-page.sync="currentPage"
      />
    </esmp-table-wrapper>
    <esmp-modal
      v-model="confirmModalShowed"
      class="modal-confirm"
      @on-ok="removeDocument"
    >
      <template #header>
        Удаление шаблона документа
      </template>
      После удаления данное действие нельзя будет отменить. Вы уверены?
    </esmp-modal>
    <form-versions
      :is-showed="versionsShowed"
      :is-compared="false"
      :form-versions-id="selectedDocumentId"
      header="Версии шаблона документа"
      type="documents"
      @hide-form-versions-modal="hideVersionsModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormVersions from '@/pages/admin/forms/components/FormVersions.vue';

export default {
  name: 'Documents',
  components: {
    FormVersions,
  },
  data() {
    return {
      isLoading: true,
      versionsShowed: false,
      confirmModalShowed: false,
      selectedDocumentId: null,
      columns: [
        {
          title: 'id',
          key: 'id',
        },
        {
          title: 'Название',
          key: 'name',
        },
        {
          title: 'Комментарий',
          key: 'comment',
        },
        {
          title: 'Версия',
          key: 'formVersionId',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
      list: [],
      pageCount: 1,
      currentPage: 1,
    };
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getDocuments() {
      this.isLoading = true;
      this.$API.documents.getDocuments({ page: this.currentPage - 1 }).then(({ data }) => {
        this.list = data.content;
        this.pageCount = data.totalPages;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    editDocument(id) {
      this.$router.push({ name: 'EditDocument', params: { id } });
    },
    removeDocument() {
      this.setLoading({ key: 'page', value: true });
      this.$API.documents.removeDocument(this.selectedDocumentId).then(() => {
        this.list = this.list.filter((f) => f.id !== this.selectedDocumentId);
        this.$EsmpNotify.$show('Шаблон документа удалён', 'success');
      }).finally(() => {
        this.selectedDocumentId = null;
        this.setLoading({ key: 'page', value: false });
      });
    },
    showConfirmModal(id) {
      this.selectedDocumentId = id;
      this.confirmModalShowed = true;
    },
    showDocumentVersions(id) {
      this.selectedDocumentId = id;
      this.versionsShowed = true;
    },
    hideVersionsModal() {
      this.versionsShowed = false;
      this.selectedDocumentId = null;
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.getDocuments();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.documents {
  .esmp-table {
    width: 100%;
    &__thead-tr,
    &__tbody-tr {
      display: grid;
      grid-template-columns: 50px 1fr 1fr 75px 150px;
    }
    &__thead-th {
      .esmp-table__thead-th-divider {
        display: none;
      }
      &:first-child,
      &:nth-child(4) {
        .esmp-table__thead-th-title-wrapper {
          justify-content: center;
        }
      }
    }
    &__tbody-td {
      &:first-child {
        text-align: center;
      }
      &:nth-child(4) {
        text-align: center;
      }
      &:last-child {
        text-align: center;
      }
    }
  }

  &__pagination {
    justify-content: center;
    padding: $base-gutter;
  }
}
</style>
