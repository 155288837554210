const controlsMap = {
  text: {
    controlName: 'text',
    viewComponentName: 'text-view',
    icon: 'control__text',
    viewName: 'Простой текст',
  },
  input: {
    controlName: 'input',
    viewComponentName: 'input-view',
    icon: 'control__input',
    viewName: 'Текстовое поле ввода',
  },
  cmdb: {
    controlName: 'cmdb',
    viewComponentName: 'cmdb-view',
    icon: 'control__cmdb',
    viewName: 'CMDB',
  },
  checkboxGroup: {
    controlName: 'checkboxGroup',
    viewComponentName: 'checkbox-group-view',
    icon: 'control__checkbox',
    viewName: 'Флажок (checkbox)',
  },
  radioGroup: {
    controlName: 'radioGroup',
    viewComponentName: 'radio-group-view',
    icon: 'control__radio',
    viewName: 'Переключатель (radiobutton)',
  },
  group: {
    controlName: 'group',
    viewComponentName: 'table-and-group-view',
    icon: 'control__group',
    viewName: 'Группа',
  },
  uploader: {
    controlName: 'uploader',
    viewComponentName: 'uploader-view',
    icon: 'control__upload',
    viewName: 'Загрузка файла',
  },
  select: {
    controlName: 'select',
    viewComponentName: 'select-view',
    icon: 'control__select',
    viewName: 'Список (listbox)',
  },
  textarea: {
    controlName: 'textarea',
    viewComponentName: 'textarea-view',
    icon: 'control__textarea',
    viewName: 'Многострочный ввод',
  },
  calendar: {
    controlName: 'calendar',
    viewComponentName: 'calendar-view',
    icon: 'control__calendar',
    viewName: 'Календарь',
  },
  graphCalendar: {
    controlName: 'graphCalendar',
    viewComponentName: 'graph-calendar-view',
    icon: 'control__calendar-graf',
    viewName: 'Графический календарь',
  },
  resourceField: {
    controlName: 'resourceField',
    viewComponentName: 'resource-field-view',
    icon: 'control__resource',
    viewName: 'Ресурсное поле',
  },
  fiasSearch: {
    controlName: 'fiasSearch',
    viewComponentName: 'fias-search-view',
    icon: 'control__fias',
    viewName: 'Фиас',
  },
  search: {
    controlName: 'search',
    viewComponentName: 'search-view',
    icon: 'control__search',
    viewName: 'Поиск',
  },
  contactInfo: {
    controlName: 'contactInfo',
    viewComponentName: 'contact-info-view',
    icon: 'control__contact',
    viewName: 'Контактная информация',
  },
  counter: {
    controlName: 'counter',
    viewComponentName: 'counter-view',
    icon: 'control__counter',
    viewName: 'Счетчик',
  },
  priority: {
    controlName: 'priority',
    viewComponentName: 'priority-view',
    icon: 'control__priority',
    viewName: 'Приоритет',
  },
  employeeReplacement: {
    controlName: 'employeeReplacement',
    viewComponentName: 'employee-replacement-view',
    icon: 'control__office',
    viewName: 'Переезд',
  },
  network: {
    controlName: 'network',
    viewComponentName: 'network-view',
    icon: 'control__web',
    viewName: 'Сеть',
  },
  table: {
    controlName: 'table',
    viewComponentName: 'table-and-group-view',
    icon: 'control__table',
    viewName: 'Таблица',
  },
  placesAndContracts: {
    controlName: 'placesAndContracts',
    viewComponentName: 'places-and-contracts-view',
    icon: 'control__docs',
    viewName: 'Площадки и договоры',
  },
  database: {
    controlName: 'database',
    viewComponentName: 'database-view',
    icon: 'control__table',
    viewName: 'Данные из БД',
  },
  scale: {
    controlName: 'scale',
    viewComponentName: 'scale-view',
    icon: 'control__scale',
    viewName: 'Шкала',
  },
  calculatedField: {
    controlName: 'calculatedField',
    viewComponentName: 'calculated-field-view',
    icon: '24-calculator',
    viewName: 'Расчётное поле',
  },
  document: {
    controlName: 'document',
    viewComponentName: 'document-view',
    icon: '24-document-edit',
    viewName: 'Документ',
  },
};

export default controlsMap;
