<template>
  <esmp-modal
    v-model="isShowed"
    class="diff-form-versions-modal"
    :closable="false"
    :mask-closable="false"
    :width="700"
  >
    <template #header>
      Сравнение версий
    </template>
    <esmp-loader v-if="isLoading" fix />
    <div
      v-else-if="!isEmpty(diffData)"
      class="diff-form-versions-modal__list"
    >
      <div
        v-for="(value, name) in diffData"
        :key="name"
        class="diff-form-versions-modal__item"
      >
        <h3 class="diff-form-versions-modal__item-name">
          {{ getDiffName(name) }}
        </h3>
        <template v-if="isArray(value)">
          <div
            class="diff-form-versions-modal__item-value"
            v-for="(control, index) in value"
            :key="index"
          >
            {{ getViewName(control.classType) }}
          </div>
        </template>
        <template v-else>
          <div
            class="diff-form-versions-modal__item-value"
            v-for="(typeValue, typeName) in value"
            :key="typeName"
          >
            {{ getVersionFieldName(typeName) }}: {{ typeValue ? typeValue : '-' }}
          </div>
        </template>
      </div>
    </div>
    <h4 v-else>
      Отличия отсутствуют
    </h4>
    <template #footer>
      <esmp-button view="outline" @click="$emit('hide-diff-form-versions-modal')">
        Закрыть
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import min from 'lodash/min';
import { DIFF_COLUMN_TYPE, VERSIONS_TYPE } from '@/constants/formVersions';
import controlsMap from '@/components/service-form/controls.map';

export default {
  name: 'DiffFormVersions',
  props: {
    isShowed: {
      type: Boolean,
      default: false,
    },
    formId: {
      type: Number,
      default: null,
    },
    diffVersionIds: {
      type: Array,
      default: null,
    },
    type: {
      type: String,
      default: 'forms',
    },
  },
  watch: {
    isShowed(val) {
      if (val) {
        this.getDiffFormVersions();
      } else {
        this.diffData = null;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      diffData: null,
    };
  },
  methods: {
    isEmpty,
    isArray,
    getDiffFormVersions() {
      this.isLoading = true;
      const originalFormVersionId = min(this.diffVersionIds);
      const changedFormVersionId = max(this.diffVersionIds);
      this.$API[this.type].getDiffFormVersions(this.formId, originalFormVersionId, changedFormVersionId)
        .then(({ data }) => {
          this.diffData = data;
        }).finally(() => {
          this.isLoading = false;
        });
    },
    getDiffName(techName) {
      const currentType = DIFF_COLUMN_TYPE.find((type) => type.techName === techName);
      return currentType ? currentType.name : 'Неизвестный тип';
    },
    getViewName(classType) {
      const controller = controlsMap[classType];
      return controller ? controller.viewName : 'Неизвестный тип';
    },
    getVersionFieldName(typeName) {
      return VERSIONS_TYPE[typeName] ? VERSIONS_TYPE[typeName] : '';
    },
  },
};
</script>

<style lang="scss">
.diff-form-versions-modal {
  &__item-value {
    margin-bottom: $base-gutter;
  }
}
</style>
